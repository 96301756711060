/** @jsx jsx */

import { Link } from 'gatsby'
import { FC } from 'react'
import { Box, jsx, Container, Text, Flex } from 'theme-ui'

import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import Navigation from '../../components/navig'

export interface AboutHomeProps {
  data: any
}

export const AboutSidebar = () => {
  return (
    <Box sx={{ pl: 0, pr: 5, minWidth: '20ch', pt: 6 }}>
      <Link to="/about/">
        <Text
          as="h2"
          sx={{ fontWeight: 700, borderBottom: 'solid 1px', pb: 3, mb: 3, color: 'grays.4' }}
          variant="personName"
        >
          About
        </Text>
      </Link>
      <Link to="/about/brand">
        <Text as="h2" variant="personName" sx={{ color: 'grays.4' }}>
          Brand
        </Text>
      </Link>
    </Box>
  )
}

const AboutHome: FC<AboutHomeProps> = ({ data }) => {
  return (
    <Layout>
      <Navigation />
      <Box variant="box.plateBig" sx={{ px: 4 }}>
        <Halo title="About" url="https://nft.rage.fan/about" />
        <Container>
          <Box>
            <Flex variant="box.plateMedium2" sx={{ color: 'text', pt: 7, pb: 6 }}>
              <AboutSidebar />
              <Box sx={{ pl: [0, 0, 5] }}>
                <Intro title="About Indelible Ink" description="" />
                <Text as="p" variant="body" sx={{ color: 'gray.0', fontWeight: 100, fontSize: 3 }}>
                  Indelible Ink is a platform to trade NFT Sports coins
                </Text>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default AboutHome
